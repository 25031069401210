type AlertProps = {
  title: string;
  message: string;
};

export default function Alert({ title, message }: AlertProps) {
  return (
    <div
      className="z-200 top-0 flex w-full justify-center px-4 py-3 pb-5"
      style={styles.alert}
    >
      <div className="max-w-lg">
        <h3 className="mt-0 font-medium">{title}</h3>
        <h5 className="mt-1 text-xs">{message}</h5>
      </div>
    </div>
  );
}

const styles = {
  alert: {
    color: '#815924',
    backgroundColor: '#FFEBD2',
  },
};
