import { apiClient } from '@booking/api';

/**
 * Create a newsletter subscription for a user in the x-crm api
 */
export async function createNewsletterSubscription(sub: {
  name?: string;
  email?: string;
  consent: boolean;
}) {
  if (!sub.email) return;
  const campaign = 'campaign:p4168subez9v0ulodlqx';
  const url = 'https://x-crm.staging.drdropin.com/users/' + campaign;
  return apiClient.post(
    url,
    {
      email: sub.email,
      name: sub.name,
      campaign,
      consents: [
        {
          name: 'email-newsletter',
          value: sub.consent ? true : false,
        },
      ],
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
}
