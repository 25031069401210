import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { ReactElement, ReactNode } from 'react';

type Props = {
  title: string | ReactElement;
  className?: string;
  children?: ReactElement | ReactElement[];
  description?: string;
  imageSrc?: string;
  onClick: (event: unknown) => void;
  TitleDecorator?: ReactNode;
  DescriptionDecorator?: ReactNode;
};

export default function StepButton({
  onClick,
  title,
  children,
  className,
  description,
  imageSrc,
  TitleDecorator,
  DescriptionDecorator,
}: Props) {
  return (
    <button
      className={classNames(
        'hover:bg-accent flex w-full flex-col justify-between overflow-hidden rounded-xl bg-white px-6 py-4 text-left text-black',
        className,
      )}
      data-testid="step-button"
      onClick={onClick}
    >
      <div className="flex w-full flex-row justify-between gap-3">
        {imageSrc ? (
          <img
            src={imageSrc}
            alt=""
            className="h-20 w-20 rounded-full object-cover"
          />
        ) : null}

        {/*Title and description*/}
        <div className="flex w-full flex-col md:text-base">
          {typeof title === 'string' ? (
            <p className="text-sm font-normal leading-[28px] md:leading-[32px]">
              {title}
            </p>
          ) : (
            title
          )}

          {description ? (
            <div className="flex items-center text-xs leading-[24px] text-black/50">
              <span>{description}</span>
            </div>
          ) : null}

          {DescriptionDecorator ? (
            <div className="flex items-center justify-between whitespace-nowrap text-xs leading-[24px]">
              {DescriptionDecorator}
            </div>
          ) : null}
        </div>

        {TitleDecorator ? (
          <div className="flex items-center justify-between whitespace-nowrap text-xs leading-[24px]">
            {TitleDecorator}
          </div>
        ) : null}
      </div>

      {children}
    </button>
  );
}

type AnimatedProps = {
  children: ReactElement | ReactElement[];
  order: number;
  className?: string;
};

const getAnimationStyle = (i: number) => ({
  hidden: { opacity: 0, translateY: '5%' },
  show: {
    opacity: 1,
    translateY: '0%',
    transition: {
      delay: i * 0.07,
      duration: 0.3,
    },
  },
});

export function AnimatedStep({ children, order, className }: AnimatedProps) {
  return (
    <motion.div
      variants={getAnimationStyle(order)}
      initial="hidden"
      animate="show"
      layout
      className={classNames('w-full', className)}
    >
      {children}
    </motion.div>
  );
}
