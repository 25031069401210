import classNames from 'classnames';

type Props = {
  text?: string;
  className?: string;
};

export default function Divider({ text, className }: Props) {
  return (
    <div
      className={classNames(
        'flex h-1 w-full justify-center rounded-full bg-white/20',
        className,
      )}
    >
      {text ? (
        <p className="bg-primary -mt-4 px-2 text-white/60">{text}</p>
      ) : null}
    </div>
  );
}
