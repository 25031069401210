import { Practitioner, TimeSlot } from '@booking/types';

import PractitionerInfo from './PractitionerInfo';
import TimePicker from './TimePicker';

type Props = {
  selectedDate: string;
  isLoading: boolean;
  daysAhead: number;
  setSelectedDate: (date: string) => void;
  onClick: (
    time?: string,
    practitionerId?: string,
    practitionerName?: string,
  ) => void;
  practitioner: Practitioner;
  timeSlotsInSelectedDate: TimeSlot[];
  nextAvailable?: { startTime: string }; // The actual next available timeslot, found either among the fetched timeslots or using the next-available call
  price?: number;
};

export default function PractitionerTimePicker({
  selectedDate,
  practitioner,
  timeSlotsInSelectedDate,
  nextAvailable,
  isLoading,
  daysAhead,
  setSelectedDate,
  onClick,
  price,
}: Props) {
  return (
    <div
      key={practitioner.id}
      className="flex w-full flex-col gap-12 md:flex-row"
    >
      <PractitionerInfo practitioner={practitioner} />

      <TimePicker
        timeSlots={timeSlotsInSelectedDate}
        selectedDate={selectedDate}
        isLoading={isLoading}
        daysAhead={daysAhead}
        practitionerId={practitioner.id}
        nextAvailableTimeSlot={nextAvailable}
        setSelectedDate={setSelectedDate}
        onClick={(time) => {
          onClick(time, practitioner.id, practitioner.name);
        }}
        price={price}
      />
    </div>
  );
}
