import { apiClient } from '@booking/api';
import * as Sentry from '@sentry/nextjs';
import { AxiosError, AxiosResponse } from 'axios';

apiClient.defaults.baseURL =
  process.env.NEXT_PUBLIC_API_URL || 'https://staging-core.api.drdropin.no';

apiClient.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    // Don't send to Sentry if the request was cancelled
    if (error.request.status !== 0) {
      Sentry.captureException(error);
    }
    return Promise.reject(error);
  },
);
