import { Locales } from '@booking/types';
import { Icon } from '@drdropin-tech/theseus';
import { TFunction } from 'i18next';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

import { Link } from '../index';

export type LocaleSwitcherProps = {
  locales?: Array<{ locale: string; labels: { nb: string; en: string } }>;
  translation: TFunction;
};

export default function LocaleSwitcher({
  locales,
  translation,
}: LocaleSwitcherProps) {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  // Close the dropdown when clicking outside of it
  useEffect(() => {
    document.addEventListener('click', (e) => {
      if (
        ref.current &&
        !(ref.current as unknown as HTMLElement).contains(e.target as Node)
      ) {
        setIsOpen(false);
      }
    });
    return () => {
      document.removeEventListener('click', () => {});
    };
  }, []);

  return (
    <div ref={ref}>
      <Icon
        role="button"
        tabIndex={1}
        aria-label={translation('navigation.switch_language')}
        name="Globe"
        onClick={() => setIsOpen(!isOpen)}
        className="cursor-pointer"
      />
      {isOpen && (
        <>
          <Icon
            name="Play"
            fill="white"
            className="absolute top-8 z-10 rotate-[-90deg]"
          />
          <div className="absolute right-0 top-12 flex flex-col items-center justify-center rounded-lg bg-white p-2 shadow-sm shadow-inherit">
            <ul>
              {locales?.map((locale) => (
                <li key={locale.locale}>
                  <Link
                    locale={locale.locale as Locales}
                    to={router.asPath}
                    className="text-black no-underline"
                  >
                    <div className="flex flex-row justify-between gap-3 p-2">
                      {router.locale === 'nb'
                        ? locale.labels.nb
                        : locale.labels.en}
                      {locale.locale === router.locale && (
                        <Icon name="Check" className="relative top-1 ml-2" />
                      )}
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );
}
