import { Vertical } from '@booking/types';
import { Icon } from '@drdropin-tech/theseus';
import { Dialog } from '@headlessui/react';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import Image from 'next/image';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

// Instansiate Dialog composable components as motion components.
// This allows us to use the Dialog as intended, without adding additional html tags.
const AnimatedDialogOverlay = motion(Dialog.Overlay);
const AnimatedDialogPanel = motion(Dialog.Panel);

type ModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  title?: string;
  description?: string;
  children?: ReactNode | ReactNode[];
  vertical?: Vertical;
  imageSrc?: string;
  iframeSrc?: string;
};

const Modal = ({
  isOpen = true,
  title,
  description,
  children,
  closeModal,
  vertical,
  imageSrc,
  iframeSrc,
}: ModalProps) => {
  useEffect(() => {
    const bind = (e: KeyboardEvent) => {
      if (e.code !== '27') {
        return;
      }

      if (
        document.activeElement &&
        ['INPUT', 'SELECT'].includes(document.activeElement.tagName)
      ) {
        return;
      }

      closeModal();
    };

    document.addEventListener('keyup', bind);
    return () => document.removeEventListener('keyup', bind);
  }, [closeModal]);

  if (!title && !description && !children) {
    title = 'Empty modal';
    description = 'Maybe you should add some content?';
    children = <div>Empty Modal</div>;
  }

  return (
    <AnimatePresence>
      {isOpen ? (
        <Dialog
          as="div"
          className={classNames('fixed inset-0 z-10 overflow-y-auto', vertical)}
          onClose={closeModal}
          open={isOpen}
        >
          <div className="flex min-h-screen items-center justify-center">
            <AnimatedDialogOverlay
              {...overlayAnimation}
              key="overlay"
              className="fixed inset-0 bg-black/50"
            />

            <AnimatedDialogPanel
              {...modalAnimation}
              key="modal"
              className="prose prose-img:m-0 lg:py-17 relative z-[10000] flex h-1/2 w-full max-w-lg flex-col rounded-xl bg-white p-8 shadow-xl lg:px-16"
            >
              <button
                onClick={closeModal}
                className="absolute right-8 top-8 cursor-pointer rounded-full bg-black/10 p-2"
              >
                <Icon name="X" appearance="primary" />
              </button>
              {imageSrc && (
                <div className="w-30 h-30 my-4 self-center">
                  <Image
                    src={imageSrc}
                    alt="Please"
                    height={120}
                    width={120}
                    className="mb-4 mt-4 self-center"
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </div>
              )}
              <Dialog.Title
                as="h2"
                className="m-5 break-words text-center text-3xl"
              >
                {title ? (
                  <span dangerouslySetInnerHTML={{ __html: title }} />
                ) : null}
              </Dialog.Title>
              <Dialog.Description
                as="p"
                className="list-inside whitespace-pre-line text-sm"
              >
                {description ? (
                  <span dangerouslySetInnerHTML={{ __html: description }} />
                ) : null}
              </Dialog.Description>

              {iframeSrc && (
                <iframe
                  src={iframeSrc}
                  className="h-full w-full"
                  style={iframeStyle}
                />
              )}
              {children}
            </AnimatedDialogPanel>
          </div>
        </Dialog>
      ) : null}
    </AnimatePresence>
  );
};

type ModalContent = {
  title?: string;
  description?: string;
  children?: ReactNode | ReactNode[];
  imageSrc?: string;
  iframeSrc?: string;
  closeModal?: () => void;
};

type OpenModal = {
  onOpen?: () => void;
  onClose?: () => void;
  content?: ModalContent;
};

type ModalCtxInterface = {
  isModalOpen: boolean;
  openModal: ({ onOpen, onClose, content }: OpenModal) => void;
  closeModal: () => void;
};

const ModalContext = createContext<ModalCtxInterface>({} as ModalCtxInterface);

type ProviderProps = {
  children: ReactNode | ReactNode[];
};

const ModalProvider = ({ children }: ProviderProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalOnClose, setModalOnClose] = useState<(() => void) | null>(null);
  const [modalContent, setModalContent] = useState<ModalContent>({});

  const closeModal = useCallback(() => {
    modalOnClose && modalOnClose();
    setModalOnClose(null);
    setIsModalOpen(false);
  }, [modalOnClose]);

  const openModal = useCallback(({ onOpen, onClose, content }: OpenModal) => {
    content && setModalContent(content);
    setIsModalOpen(true);

    onOpen && onOpen();
    onClose && setModalOnClose(onClose);
  }, []);

  const contextValues = useMemo(
    () => ({ isModalOpen, openModal, closeModal }),
    [closeModal, isModalOpen, openModal],
  );

  return (
    <ModalContext.Provider value={contextValues}>
      <Modal closeModal={closeModal} isOpen={isModalOpen} {...modalContent} />
      {children}
    </ModalContext.Provider>
  );
};

const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }

  return context;
};

const modalAnimation = {
  initial: {
    opacity: 0,
    y: 0,
    scale: 0.95,
  },
  animate: {
    opacity: 1,
    y: 0,
    scale: 1,
  },
  exit: {
    opacity: 0,
    y: 0,
    scale: 0.95,
  },
  transition: {
    ease: 'easeInOut',
    duration: 0.2,
  },
};

const overlayAnimation = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
  transition: {
    ease: 'easeInOut',
    duration: 0.2,
  },
};

const iframeStyle = {
  minHeight: '60vh',
};

export { ModalProvider, useModal };
