import { Spinner } from '@drdropin-tech/theseus';
import React from 'react';

const SpinnerWrapper = () => (
  <div className="flex justify-center">
    <Spinner size="xl" />
  </div>
);

export default SpinnerWrapper;
