import { getUniqueDayList, WeekDay } from '@booking/shared';
import { Actions, TimeSlot } from '@booking/types';
import { DateButton, DateScrollButtons } from '@booking/ui-web';
import useAnalytics from '@hooks/useAnalytics';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type DatePickerProps = {
  selectedDate: string;
  weeks: WeekDay[][];
  currentWeekIndex: number;
  setSelectedDate: (date: string) => void;
  timeSlots: TimeSlot[];
  canGoBack: boolean;
  isLoading?: boolean;
  next: () => void;
  prev: () => void;
};

export default function DatePicker({
  selectedDate,
  weeks,
  currentWeekIndex,
  canGoBack,
  timeSlots,
  next,
  prev,
  setSelectedDate,
  isLoading,
}: DatePickerProps) {
  const setEventProps = useAnalytics();

  const { t } = useTranslation(['common', 'booking']);

  const uniqueDayList = useMemo(() => getUniqueDayList(timeSlots), [timeSlots]);

  const handleTrackingEvent = (action: Actions, label: string) => {
    setEventProps({ event: { action, date: label } });
  };

  return (
    <>
      <div className="mx-auto flex w-full max-w-[512px] snap-x snap-mandatory overflow-hidden">
        <div
          className="flex w-full flex-1 gap-2 px-1 py-2 transition-all duration-500 ease-in-out"
          style={{
            translate: `calc(${currentWeekIndex * -100}%)`,
          }}
          data-testid="select-date"
        >
          {weeks?.map((week, i) => {
            return (
              <ol
                key={i}
                className="flex w-full min-w-full flex-1 snap-center snap-always gap-2"
                data-week={i}
              >
                {week.map(({ formatted }, index) => {
                  const { dateString } = formatted;

                  /*
                   * Check if the date is available
                   * uniqueDayList is a tuple of a date and a boolean.
                   */
                  const isAvailable = uniqueDayList.some(
                    (day) => day[0].includes(dateString) && day[1] === true,
                  );
                  return (
                    <li key={dateString} className="flex w-full flex-1">
                      {isLoading ? (
                        <button
                          className="h-30 skeleton w-full rounded-lg bg-white/30"
                          style={{ animationDelay: `${0.04 * index}s` }}
                          disabled
                        />
                      ) : (
                        <DateButton
                          day={dateString}
                          isAvailable={isAvailable}
                          className="flex flex-1 flex-col items-center justify-center gap-2 rounded-lg px-1 py-4 transition-all duration-300 ease-in-out md:px-3"
                          selectedDay={selectedDate}
                          onClick={setSelectedDate}
                          i18nFunction={t}
                          trackingEvent={handleTrackingEvent}
                        />
                      )}
                    </li>
                  );
                })}
              </ol>
            );
          })}
        </div>
      </div>
      <DateScrollButtons
        forward={() => next()}
        backward={() => prev()}
        canGoBack={canGoBack}
      />
    </>
  );
}
