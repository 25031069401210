import { useNavigationStore, usePersonalDataStore } from '@booking/shared';
import { StepWrapper } from '@booking/ui-web';
import Receipt from '@components/Receipt';
import { Button } from '@drdropin-tech/theseus';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

export default function ReceiptStep() {
  const { t } = useTranslation(['common', 'booking']);
  const queries = useNavigationStore((state) => state.queries);

  const removeQueries = useNavigationStore.getState().removeQueries;
  const clearPersonalDetails =
    usePersonalDataStore().getState().clearPersonalDetails;

  const handleClearStorage = () => {
    clearPersonalDetails();
    removeQueries();
    sessionStorage.clear();
  };

  const { partner } = queries;
  const isStorebrand = partner === 'storebrand';

  return (
    <StepWrapper
      title={t('steps.receipt.title', { ns: 'booking' })}
      stepWidth="max-w-[441px]"
      imageSrc="/illustrations/doctor.png"
    >
      <Receipt />

      {!isStorebrand && (
        <Link passHref href="/">
          <Button
            appearance="accent"
            className="btn btn-accent mt-10 w-full font-normal normal-case"
            onClick={handleClearStorage}
          >
            {t('back_to_homepage')}
          </Button>
        </Link>
      )}
    </StepWrapper>
  );
}
