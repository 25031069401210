import { AnimatePresence, motion, PanInfo, Variants } from 'framer-motion';
import { useCallback, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useLockedBody, useOnClickOutside } from 'usehooks-ts';

type BioBannerProps = {
  isOpen: boolean;
  closePanel: () => void;
  children: React.ReactNode;
};

type PanEvent = MouseEvent | TouchEvent | PointerEvent;

function BioPanel({ isOpen, closePanel, children }: BioBannerProps) {
  const panelRef = useRef<HTMLDivElement>(null);
  const contentHeight = panelRef.current ? panelRef.current.clientHeight : 0;

  const handleDragEnd = useCallback(
    (_: PanEvent, info: PanInfo) => {
      if (info.velocity.y > 300) {
        closePanel();
      }
    },
    [contentHeight],
  );

  const dragProps = {
    drag: 'y' as const,
    dragSnapToOrigin: true,
    dragPropagation: true,
    dragConstraints: { top: 0, bottom: 0 },
    dragElastic: { top: 0, bottom: 0.5 },
    onDragEnd: handleDragEnd,
  };

  const transition = {
    type: 'tween',
    duration: 0.35,
  };

  const variants: Variants = {
    active: {
      y: '0%',
      transition,
    },
    inactive: {
      y: '100%',
      transition,
    },
  };

  useOnClickOutside(panelRef, closePanel);
  useLockedBody(isOpen, 'root');

  return createPortal(
    <>
      <AnimatePresence>
        {isOpen && (
          <>
            <motion.div
              key="overlay"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0"
            >
              <div className="visible fixed inset-0 bg-black/50" />
            </motion.div>

            <motion.div
              key="panel"
              {...dragProps}
              className="fixed inset-x-0 bottom-0 flex max-h-full flex-col overflow-hidden rounded-t-md bg-white text-black md:mx-auto md:max-w-screen-sm"
              initial="inactive"
              variants={variants}
              animate={isOpen ? 'active' : 'inactive'}
              exit="inactive"
              ref={panelRef}
            >
              <div className="flex flex-col overflow-hidden px-8 pb-4 pt-6">
                <span className="bg-gray-dark mb-2 flex h-2 w-10 self-center rounded-full" />
                {children}
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </>,
    document.querySelector('body')!,
  );
}

export default BioPanel;
