import { Icon } from '@drdropin-tech/theseus';
import classNames from 'classnames';

type Props = {
  shouldHighlight?: boolean;
  icon: 'ChevronDown' | 'ChevronRight' | 'Video';
  className?: string;
};
export default function HighlightedIcon({
  shouldHighlight,
  icon,
  className,
}: Props) {
  return (
    <span
      className={classNames(
        {
          'bg-black/12 transition-all': shouldHighlight,
        },
        'rounded-full p-1',
        className,
      )}
    >
      <Icon name={icon} iconSize="md" />
    </span>
  );
}
