import Image from 'next/image';
import { useTranslation } from 'next-i18next';

function NoAvailableTimeSlotsBanner() {
  const { t } = useTranslation('booking');
  return (
    <div
      className="flex w-full flex-col items-center justify-center gap-4 rounded-lg bg-black/20 p-6"
      data-testid="no-available-slots"
    >
      <Image
        src={'/illustrations/calendar.png'}
        alt="no available slots"
        width={80}
        height={80}
        style={{
          maxWidth: '100%',
          height: 'auto',
        }}
      />
      <div className="flex flex-col gap-4">
        <span>
          {t('steps.select_datetime.therapist_fully_booked', {
            ns: 'booking',
          })}
        </span>
        {/*Uncomment this when next-available-appointment is live and ready. nextAvailableTimeSlot && (
                <button
                  className="underline underline-offset-4"
                  onClick={() => {
                    if (nextAvailableTimeSlot && onChange) {
                      onChange(nextAvailableTimeSlot.startTime)
                    }
                  }}
                >
                  {format(
                    parseISO(nextAvailableTimeSlot.startTime),
                    'EEE d MMM',
                    {
                      locale: nb,
                    },
                  )}
                </button>
              )*/}
      </div>
    </div>
  );
}

export default NoAvailableTimeSlotsBanner;
