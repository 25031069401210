import { Icon } from '@drdropin-tech/theseus';
import { FieldError } from 'react-hook-form';

type InputErrorProps = {
  isDirty: boolean;
  errors: FieldError | undefined;
};

// This should be moved to a custom input component and handled
// within the input component itself

export default function InputError({ isDirty, errors }: InputErrorProps) {
  return (
    <>
      {isDirty && !errors && (
        <div className="absolute inset-y-0 right-5 flex items-center">
          <Icon
            name="CheckCircle"
            appearance="primary"
            className="animated-checkmark"
          />
        </div>
      )}
      {errors && (
        <div className="absolute inset-y-0 right-5 flex items-center">
          <Icon name="AlertOctagon" className="stroke-error" />
        </div>
      )}
    </>
  );
}
