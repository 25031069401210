import {
  ComponentPropsWithoutRef,
  ElementType,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useState,
} from 'react';

import AccordionButton from './AccordionButton';
import AccordionContext from './AccordionContext';
import AccordionItem from './AccordionItem';
import AccordionPanel from './AccordionPanel';

type AccordionProps = {
  defaultActiveItem?: string;
};

export type Children = ReactNode | ReactNode[];

type AsProp<C extends ElementType> = {
  as?: C;
};

type PropsToOmit<C extends ElementType, P> = keyof (AsProp<C> & P);

export type PolymorphicComponentProps<
  C extends ElementType,
  Props = Record<string, never>,
> = PropsWithChildren<Props & AsProp<C>> &
  Omit<ComponentPropsWithoutRef<C>, PropsToOmit<C, Props>>;

type Props<C extends ElementType> = PolymorphicComponentProps<
  C,
  AccordionProps
>;

const Accordion = <C extends ElementType = 'div'>({
  as,
  defaultActiveItem = '',
  children,
  ...restProps
}: Props<C>) => {
  const [activeItem, setActiveItem] = useState(defaultActiveItem);

  const changeSelectedItem = useCallback(
    (value: string) => {
      if (activeItem !== value) setActiveItem(value);
    },
    [setActiveItem, activeItem],
  );
  const Component = as || 'div';
  return (
    <AccordionContext.Provider value={{ activeItem, changeSelectedItem }}>
      <Component {...restProps}>{children}</Component>
    </AccordionContext.Provider>
  );
};

Accordion.Item = AccordionItem;
Accordion.Panel = AccordionPanel;
Accordion.Button = AccordionButton;

export default Accordion;
