import { Practitioners } from '@booking/api';
import {
  useClinicStore,
  useNavigationStore,
  useServiceTreeStore,
} from '@booking/shared';
import { Actions, Practitioner } from '@booking/types';
import {
  AnimatedStep,
  Divider,
  SpinnerWrapper,
  StepButton,
  StepWrapper,
} from '@booking/ui-web';
// import AvailabilityDecorator from '@components/AvailabilityDecorator';
import useAnalytics from '@hooks/useAnalytics';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';
import React, { useRef } from 'react';
import { shallow } from 'zustand/shallow';

export default function SelectPractitionerStep() {
  const { t } = useTranslation('booking');
  const setEventProps = useAnalytics();

  const nextAvailableMap = useRef(new Map<string, number>());

  const { queries, advanceStep, setQueries } = useNavigationStore(
    (state) => ({
      setQueries: state.setQueries,
      advanceStep: state.advanceStep,
      queries: state.queries,
    }),
    shallow,
  );

  const { clinic } = useClinicStore()((state) => ({
    clinic: state.clinic,
  }));

  const { getServiceUuid } = useServiceTreeStore(
    (state) => ({
      getServiceUuid: state.getServiceUuid,
    }),
    shallow,
  );

  if (queries.vertical !== 'mental-health') {
    advanceStep();
  }

  const serviceUuid = getServiceUuid(queries.service);

  const { data: practitioners, isLoading } = useQuery(
    ['practitioners', nextAvailableMap],
    ({ signal }) =>
      Practitioners.getPractitioners({
        serviceId: serviceUuid,
        clinicId: clinic?.id,
        signal,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: !!serviceUuid,
      keepPreviousData: true,
      select: (data) => {
        // let practitionersWithNextAvailable = data;
        //
        // if (nextAvailableMap.current) {
        //   practitionersWithNextAvailable = practitionersWithNextAvailable.map(
        //     (practitioner) => {
        //       const slot = nextAvailableMap.current.get(practitioner.id);
        //       return { ...practitioner, nextAvailable: slot };
        //     },
        //   );
        // }

        return data;
      },
    },
  );

  const handleClick = (
    practitioner?: Practitioner,
    practitionerIndex?: number,
  ) => {
    if (practitioner) {
      setQueries({ practitionerId: practitioner.id });

      setEventProps({
        event: {
          action: Actions.SelectPractitioner,
          vertical: queries.vertical,
          speciality: queries.speciality,
          service: queries.service,
          clinic: clinic?.shortId,
          practitionerId: practitioner.id,
          practitionerNumber: practitionerIndex
            ? practitionerIndex + 1
            : undefined,
        },
      });
    } else {
      setEventProps({
        event: {
          action: Actions.SkipSelectPractitioner,
          vertical: queries.vertical,
          speciality: queries.speciality,
          service: queries.service,
          clinic: clinic?.shortId,
        },
      });
    }

    advanceStep();
  };

  // const addToNextAvailableArray = (practitionerId: string, time: number) => {
  //   nextAvailableMap.current.set(practitionerId, time);
  // };

  return (
    <StepWrapper
      isLoading={isLoading}
      stepWidth="max-w-[512px]"
      title={t('steps.select_practitioner.title')}
    >
      <AnimatedStep order={0}>
        <StepButton
          title={
            <h2 className="text-black">
              {t('steps.select_practitioner.see_all_available_appointments')}
            </h2>
          }
          className="mb-3 py-8"
          onClick={() => {
            handleClick();
          }}
        />

        <Divider
          text={t('steps.select_practitioner.or_choose')}
          className="mb-10 mt-12"
        />
      </AnimatedStep>
      {isLoading ? (
        <SpinnerWrapper />
      ) : (
        practitioners?.map((practitioner: Practitioner, index: number) => (
          <AnimatedStep key={practitioner.id} order={index}>
            <StepButton
              title={
                <div className="flex h-full w-full flex-row items-center">
                  <p className="text-md text-black">{practitioner.name}</p>
                </div>
              }
              // DescriptionDecorator={
              //   serviceUuid ? (
              //     <AvailabilityDecorator
              //       serviceId={serviceUuid}
              //       resourceIds={practitioner.resourceIds}
              //       index={index}
              //       locale={locale}
              //       addToNextAvailableArray={addToNextAvailableArray}
              //     />
              //   ) : null
              // }
              onClick={() => {
                handleClick(practitioner, index);
              }}
              imageSrc={
                practitioner.photoUrl || '/illustrations/square-doctor.png'
              }
              className="group"
            />
          </AnimatedStep>
        ))
      )}
    </StepWrapper>
  );
}
