import {
  Children,
  cloneElement,
  ComponentPropsWithoutRef,
  ElementType,
  PropsWithChildren,
  ReactElement,
  ReactNode,
} from 'react';

type ItemProps = {
  children: ReactNode | ReactNode[];
  label: string;
  className?: string;
};

export type Children = ReactNode | ReactNode[];

type AsProp<C extends ElementType> = {
  as?: C;
};

type PropsToOmit<C extends ElementType, P> = keyof (AsProp<C> & P);

export type PolymorphicComponentProps<
  C extends ElementType,
  Props = Record<string, never>,
> = PropsWithChildren<Props & AsProp<C>> &
  Omit<ComponentPropsWithoutRef<C>, PropsToOmit<C, Props>>;

type Props<C extends ElementType> = PolymorphicComponentProps<C, ItemProps>;

// AccordionItem component
const AccordionItem = <C extends ElementType = 'div'>({
  children,
  label,
  as,
  ...restProps
}: Props<C>) => {
  const Component = as || 'div';

  // label is used to distinguish between each accordion element.
  // Adding the label prop to the children of accordionItem along with other props.
  const itemChildren = Children.map(children, (child) => {
    const item = child as ReactElement<PropsWithChildren<{ label: string }>>;
    return cloneElement(item, {
      ...item.props,
      label,
    });
  });
  return <Component {...restProps}>{itemChildren}</Component>;
};

export default AccordionItem;
