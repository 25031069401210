import { Appointments } from '@booking/api';
import { useEffect, useState } from 'react';

export default function useNextAvailableByPractitioner(
  practitionerIds: string[],
  serviceId: string,
  clinicId: string,
  fromDate: string,
) {
  const [fetchedNextAvailable, setFetchedNextAvailable] = useState<
    Record<string, { startTime: string }>
  >({});

  useEffect(() => {
    const getAllNextAvailable = async () => {
      const responses = await Promise.all(
        practitionerIds.map(async (practitionerId) => {
          const response = await Appointments.getNextAvailable({
            practitionerIds: [practitionerId],
            serviceId,
            clinicId,
            fromDate,
          });
          return { practitionerId, timeSlot: response[0] };
        }),
      );
      const fetchedNextAvailableTimeSlotsByPractitionerId = responses.reduce(
        (acc: Record<string, { startTime: string }>, current) => {
          if (current.timeSlot) {
            acc[current.practitionerId] = current.timeSlot;
          }
          return acc;
        },
        {},
      );
      setFetchedNextAvailable(fetchedNextAvailableTimeSlotsByPractitionerId);
    };
    getAllNextAvailable();
  }, [practitionerIds, serviceId, clinicId, fromDate]);

  return fetchedNextAvailable;
}
