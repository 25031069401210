import { Actions, Locales, Practitioner } from '@booking/types';
import { TextButton } from '@booking/ui-web';
import BioPanel from '@components/BioPanel/BioPanel';
import useAnalytics from '@hooks/useAnalytics';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';

type Props = {
  practitioner: Practitioner;
  locale: Locales;
};

export default function PractitionerInfo({ practitioner, locale }: Props) {
  const [bioPanelOpen, setBioPanelOpen] = useState(false);
  const { t } = useTranslation(['booking']);
  const setEventProps = useAnalytics();

  const closePanel = () => setBioPanelOpen(false);

  function toggleDrawer() {
    setBioPanelOpen((prev) => !prev);

    if (bioPanelOpen) {
      setEventProps({
        event: {
          action: Actions.OpenPractitionerInfo,
          practitionerId: practitioner.id,
        },
      });
    }
  }

  return (
    <div>
      <div className="mb-4 flex flex-row items-center space-x-4">
        <Image
          src={practitioner.photoUrl || '/illustrations/square-doctor.png'}
          alt="Practitioner image"
          width={56}
          height={56}
          className="h-20 w-20 rounded-full object-cover"
        />
        <div className="flex flex-col">
          <span className="text-[20px]">{practitioner.name}</span>
          <TextButton
            text={t(
              'steps.select_datetime.summary.practitioner_read_more_button',
            )}
            onClick={toggleDrawer}
          />
        </div>
      </div>

      <BioPanel isOpen={bioPanelOpen} closePanel={closePanel}>
        <div className="flex flex-col gap-4 overscroll-contain">
          <span className="flex items-center gap-2 font-medium">
            <Image
              src={practitioner.photoUrl || '/illustrations/square-doctor.png'}
              alt="Practitioner image"
              width={56}
              height={56}
              className="h-20 w-20 rounded-full object-cover"
            />
            {practitioner.name}
          </span>
          <p className="overscroll-contain text-sm">
            {practitioner.description[locale]}
          </p>
        </div>
      </BioPanel>
    </div>
  );
}
