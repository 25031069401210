import classNames from 'classnames';
import { motion, useAnimation } from 'framer-motion';
import { useEffect } from 'react';

import { useAccordionContext } from './AccordionContext';
import renderChildrenWithProps, { Children } from './renderChildrenWithProps';

type Props = {
  children: Children<{ isSelected: boolean }>;
  label?: string;
};

export default function AccordionPanel({ children, label }: Props) {
  const controls = useAnimation();
  const { activeItem } = useAccordionContext();
  const variants = {
    expanded: {
      height: 'unset',
      transition: {
        duration: 0.125,
      },
    },
    collapsed: {
      height: '0px',
      transition: {
        duration: 0.25,
      },
    },
  };

  useEffect(() => {
    if (activeItem === label) {
      controls.start('expanded');
    } else {
      controls.start('collapsed');
    }
  }, [controls, activeItem, label]);

  return (
    <motion.div
      initial="collapsed"
      className={classNames(
        { 'overflow-x-hidden overflow-y-hidden': activeItem !== label },
        'z-0 mx-3 overflow-hidden rounded-b-xl bg-white',
      )}
      animate={controls}
      transition={{ duration: 0.33 }}
      variants={variants}
    >
      {renderChildrenWithProps(children, { isSelected: activeItem === label })}
    </motion.div>
  );
}
