import { ServiceCategory } from '@booking/types';
import { Link } from '@drdropin-tech/theseus';
import NextLink from 'next/link';

type Props = {
  service: ServiceCategory;
};
export default function ServiceCard({
  service: { title, description, to, name, services },
}: Props) {
  return (
    <div className="bg-tertiary grid h-full grid-rows-[auto,1fr,1fr,auto] items-start justify-start gap-3 rounded-md p-6">
      <h2 className="prose-2xl">{title}</h2>
      <p className="prose-md">{description}</p>
      <div className="flex flex-wrap gap-2">
        {services?.map((service) => (
          <div key={service} className="pill pill-secondary">
            {service}
          </div>
        ))}
      </div>
      <NextLink href={to ?? `/booking/${name}`} passHref>
        <Link
          as="button"
          buttonProps={{
            theme: 'dark-contrast',
            buttonSize: 'sm',
          }}
        >
          Book time
        </Link>
      </NextLink>
    </div>
  );
}
