import { Clinic } from '@booking/types';

const GOOGLE_MAPS_BASE_URL = 'https://maps.google.com/';

export const clinicToGoogleMapsLink = (clinic?: Clinic) => {
  if (!clinic) return GOOGLE_MAPS_BASE_URL;
  const address =
    clinic?.address &&
    clinic?.address.street &&
    clinic?.address.zip &&
    clinic?.address.city
      ? encodeURIComponent(
          `${clinic?.address.street}, ${clinic?.address.zip} ${clinic?.address.city}`,
        )
      : '';
  const clinicName = encodeURIComponent(
    clinic?.name?.includes('Dr.Dropin')
      ? clinic.name
      : `Dr.Dropin ${clinic?.name}`,
  );
  return `${GOOGLE_MAPS_BASE_URL}?q=${clinicName}+${address}`;
};
