import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useCallback } from 'react';

import { useAccordionContext } from './AccordionContext';
import renderChildrenWithProps, { Children } from './renderChildrenWithProps';

type AccordionButtonProps = {
  label?: string;
  className?: string;
  onClick?: (label: string) => void;
  children: Children<{ isSelected: boolean }>;
};

// AccordionButton component
export default function AccordionButton({
  label,
  children,
  className,
  onClick,
}: AccordionButtonProps) {
  const { changeSelectedItem, activeItem } = useAccordionContext();
  const isSelected = activeItem === label;

  const handleClick = useCallback(() => {
    if (onClick) onClick(label!);
    else changeSelectedItem(isSelected ? '' : label!);
  }, [changeSelectedItem, isSelected, label, onClick]);

  return (
    <motion.button
      className={classNames(
        {
          'bg-accent': isSelected,
          'bg-white': !isSelected,
        },
        'hover:bg-accent flex w-full items-center justify-between gap-2 rounded-xl border-b-[1px] px-6 py-5',
        className,
      )}
      onClick={handleClick}
    >
      {renderChildrenWithProps(children, { isSelected })}
    </motion.button>
  );
}
