import { Locales } from '@booking/types';
import { Link as ThLink } from '@drdropin-tech/theseus';
import { ButtonLinkProps } from '@drdropin-tech/theseus/lib/components/Link/Link';
import NextLink from 'next/link';
import { AnchorHTMLAttributes, ReactNode } from 'react';

type Props = {
  as?: 'link' | 'button';
  to: string;
  className?: string;
  children: ReactNode;
  disabled?: boolean;
  buttonProps?: ButtonLinkProps;
  isExternalLink?: boolean;
  locale?: Locales;
} & AnchorHTMLAttributes<HTMLAnchorElement>;

export default function Link({
  as = 'link',
  buttonProps,
  to = '',
  rel = 'norefferer',
  className,
  children,
  disabled,
  isExternalLink,
  locale = 'nb',
}: Props) {
  if (disabled) {
    return <div className={`${className} pointer-events-none`}>{children}</div>;
  }

  const isOutsideOfNext = to?.includes('https') || isExternalLink;

  if (isOutsideOfNext) {
    return (
      <ThLink
        href={to}
        as={as}
        title={`Link to: ${to}`}
        className={className}
        target="_blank"
        rel={rel}
      >
        {children}
      </ThLink>
    );
  }

  const localizedPrefix = locale === 'nb' ? '' : '/en';
  const href = `${localizedPrefix}${to}`;
  const btnProps = as === 'button' ? buttonProps : {};
  return (
    <NextLink passHref href={href} locale={locale}>
      <ThLink as={as} className={className} {...btnProps}>
        {children}
      </ThLink>
    </NextLink>
  );
}
