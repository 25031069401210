import { Icon } from '@drdropin-tech/theseus';

type Props = {
  text?: string;
  onClick?: () => void;
};

export default function RightArrowButton({ text, onClick }: Props) {
  return (
    <button
      className="mb-8 flex w-full items-center justify-between"
      onClick={onClick}
    >
      <span>{text}</span>
      <div className="text-primary hover:bg-accent rounded-full bg-white p-1">
        <Icon name="ArrowRight" />
      </div>
    </button>
  );
}
