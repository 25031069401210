import { createContext, useContext } from 'react';

type AccordionContextInterface = {
  activeItem: string;
  changeSelectedItem: (item: string) => void;
};

const AccordionContext = createContext<AccordionContextInterface>({
  activeItem: '',
  changeSelectedItem: () => {},
});

export default AccordionContext;

export const useAccordionContext = () => {
  const context = useContext(AccordionContext);
  if (!context) {
    throw new Error('Error in creating the context');
  }
  return context;
};
