import { Appointments, SMS } from '@booking/api';
import {
  formatStartTime,
  getDaysUntil,
  getFormattedPhoneNumber,
  Query,
  useClinicStore,
  useNavigationStore,
  usePersonalDataStore,
  useServiceTreeStore,
} from '@booking/shared';
import {
  Actions,
  Appointment,
  BookAppointmentRequest,
  Locales,
  Service,
} from '@booking/types';
import { SpinnerWrapper, StepWrapper } from '@booking/ui-web';
import { Button } from '@drdropin-tech/theseus';
import useAnalytics from '@hooks/useAnalytics';
import * as Sentry from '@sentry/nextjs';
import { useMutation } from '@tanstack/react-query';
import APP_IDENTIFIER from '@utils/versionString';
import { AxiosError } from 'axios';
import classNames from 'classnames';
import _get from 'lodash/get';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { FormEvent, useEffect, useMemo, useRef, useState } from 'react';
import * as z from 'zod';

// Separate function get the uuid from a service
// as well as hacks for partner specific services
const getServiceId = (
  partner: string,
  serviceMap: Map<string, Service>,
  queries: Query,
) => {
  if (partner === 'storebrand') {
    return serviceMap.get('storebrandConsultation')?.id;
  }

  // This needs to be fixed along with all other partner logic
  if (partner === 'healthCheck') {
    return serviceMap.get('healthCheck')?.id;
  }

  return serviceMap.get(queries.service)?.id;
};

const resendDelay = 30 * 1000; // 30 seconds

interface InitiatePhoneVerificationError {
  response: {
    data: {
      message: string;
    };
  };
}

interface VerifyCodeResponseError {
  status: number;
  code: number;
  moreInfo: string;
}

interface PhoneVerificationErrorMessage {
  message: string;
  error: string;
}

export default function VerificationStep() {
  const { t } = useTranslation(['booking', 'common']);
  const [error, setError] = useState('');
  const setEventProps = useAnalytics();
  const locale = useRouter().locale as Locales;

  const codeSchema = z.object({
    code: z
      .string()
      .regex(/\d{6}/, t('verify.error_message', { ns: 'booking' })),
  });

  const errorMessages: PhoneVerificationErrorMessage[] = [
    {
      message: t('steps.verify.error.wrong_code', { ns: 'booking' }),
      error: 'Wrong code',
    },
    {
      message: t('steps.verify.error.too_many_attempts', { ns: 'booking' }),
      error: 'Too many attempts',
    },
    {
      message: t('steps.verify.error.code_already_used', { ns: 'booking' }),
      error: 'Code has already been used',
    },
    {
      message: t('steps.verify.error.appointment_in_past', { ns: 'booking' }),
      error: 'Appointment in past',
    },
    {
      message: t('steps.verify.error.appointment_not_available', {
        ns: 'booking',
      }),
      error: 'The selected time is not available',
    },
    {
      message: t('steps.verify.error.no_response', { ns: 'booking' }),
      error: 'No response recieved',
    },
    // error messages during an appointment reservation has changed for some reason.
    // The following errors are the latest
    {
      message: t('steps.verify.error.startTime_invalid_datetime', {
        ns: 'booking',
      }),
      error: 'startTime invalid datetime; ', // format error in booking data
    },
    {
      message: t('steps.verify.error.appointment_in_past', {
        ns: 'booking',
      }),
      // appointment in the past. The date varies in the error so the error is not static.
      // we cannot use this in a comparaion test.
      error: '2024-01-0511:20:00.000Z is in the past',
    },
    {
      message: t('steps.verify.error.no_available_appointments', {
        ns: 'booking',
      }),
      error: 'No available appointments', // apppointment already taken
    },
    {
      message: t('steps.verify.error.timeslot_unavailable', {
        ns: 'booking',
      }),
      error: 'timeslot unavailable', // apppointment already taken
    },
  ];

  const isRebookingRequired = (error: string) => {
    // return true if an error require to choose another time slot
    return (
      [
        'Appointment in past',
        'The selected time is not available',
        'No available appointments',
        'timeslot unavailable',
      ].includes(error) || error?.includes('in the past')
    );
  };

  const [code, setCode] = useState('');
  const [_codeError, setCodeError] = useState<string>('');
  const [lastResend, setLastResend] = useState(0);
  const [tick, updateTick] = useState(0);

  const personalDetails = usePersonalDataStore()(
    (state) => state.personalDetails,
  );
  const { advanceStep, queries, goToStep, currStep } = useNavigationStore(
    (state) => ({
      advanceStep: state.advanceStep,
      queries: state.queries,
      currStep: state.currStep,
      goToStep: state.goToStep,
    }),
  );

  const clinic = useClinicStore()((state) => state.clinic);
  const serviceMap = useServiceTreeStore((state) => state.serviceMap);

  const formattedPhoneNumber = useMemo(
    () => getFormattedPhoneNumber(personalDetails?.phone),
    [personalDetails?.phone],
  );

  // Initiate phone verification mutation
  const initiate = useMutation<
    SMS.TwilioResponse,
    InitiatePhoneVerificationError,
    Record<'phoneNumber', string> & Record<'locale', Locales | undefined>
  >(({ phoneNumber, locale }) => SMS.getCode({ phoneNumber, locale }));

  // Verify phone number mutation
  const verify = useMutation<
    SMS.TwilioResponse,
    VerifyCodeResponseError,
    Record<'phoneNumber', string> & Record<'code', string>
  >(({ phoneNumber, code }) => SMS.verifyCode({ phoneNumber, code }));

  // Book appointment mutation
  const bookAppointment = useMutation<
    Appointment,
    AxiosError<Record<'message', string>>,
    Appointments.CreateAppointmentProps
  >((bookingData) => Appointments.createAppointment(bookingData));

  // code validation
  useEffect(() => {
    const result = codeSchema.safeParse({
      code,
    });

    if (result.success) setCodeError('');
    else setCodeError(result.error.issues[0]?.message);

    if (code.length === 6 && result.success) {
      verifyPhone();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  async function initiateVerification() {
    if (lastResend + resendDelay > Date.now()) return;

    setLastResend(Date.now());

    initiate.mutate(
      { phoneNumber: personalDetails.phone!, locale: locale },
      {
        onError: (error) => {
          setError(error.response.data.message);
          Sentry.captureMessage(error.response.data.message);
        },
      },
    );
  }

  // Make sure we only send the request once
  const verifyPhoneActive = useRef(false);

  async function verifyPhone(e?: FormEvent) {
    e?.preventDefault();

    if (initiate.isSuccess && !verifyPhoneActive.current) {
      verifyPhoneActive.current = true;

      verify.mutate(
        { phoneNumber: personalDetails.phone!, code: code },
        {
          onSuccess: (data) => {
            if (data.valid) {
              handleSubmit();
              setEventProps({
                event: {
                  action: Actions.PhoneVerificationSuccess,
                },
                target: {
                  posthog: true,
                },
              });
            } else {
              setError('Wrong code');
              Sentry.captureMessage('Wrong code');
            }
            verifyPhoneActive.current = false;
          },
          onError: (error) => {
            if (error?.code === 20404) {
              setError('Code has already been used');
              Sentry.captureMessage('Code has already been used');
            } else {
              setError(error.moreInfo);
            }
            Sentry.captureMessage(error.moreInfo);
            verifyPhoneActive.current = false;
          },
        },
      );
    }
  }

  useEffect(() => {
    initiateVerification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resendDisabled = useMemo(
    () => lastResend + resendDelay > Date.now(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lastResend, tick],
  );

  // tick (countdown)
  useEffect(() => {
    if (resendDisabled) {
      const tickInterval = setInterval(
        () => updateTick((t) => t + 1),
        resendDelay,
      );
      return () => clearInterval(tickInterval);
    }
  }, [resendDisabled]);

  // Handle booking submit. Will advance to next step if successful
  async function handleSubmit(e?: MouseEvent) {
    e?.preventDefault();

    // Check if appointment is in the past
    const now = new Date();
    const appointmentTime = formatStartTime(personalDetails);
    if (now > new Date(appointmentTime)) {
      setError('Appointment in past');
    } else {
      setError('');

      const phoneWithoutCountryCode = personalDetails
        .phone!.replace(/\s/g, '')
        .replace(personalDetails.countryCode!, '');

      const partnerInfo = {
        partnerId: personalDetails.partnerId,
        membershipCampaignId: personalDetails.membershipCampaignId,
      };

      const bookingData: BookAppointmentRequest = {
        patientInfo: {
          ...personalDetails,
          phone: phoneWithoutCountryCode,
          birthdate: personalDetails.birthdate?.split('/').join('-'),
        },
        practitionerId: personalDetails?.practitionerId,
        description: personalDetails.partnerPrice
          ? `${personalDetails.description} - ${personalDetails.partnerPrice}`
          : `${personalDetails.description}`,
        serviceId: getServiceId(queries.partner, serviceMap, queries),
        startTime: appointmentTime,
        clinicId: clinic?.id,
        bookingOrigin: APP_IDENTIFIER,
        ...partnerInfo,
      };

      // Example bookingData object
      // {
      //   "patientInfo": {
      //     "date": "2024-01-05",
      //     "time": "12:20",
      //     "name": "Johannes Berggren",
      //     "birthdate": "22-11-1993",
      //     "phone": "41500999",
      //     "description": "Tester",
      //     "countryCode": "+47",
      //     "selectedCountry": "NO"
      // },
      //   "description": "Tester",
      //   "serviceId": "6a6ad45f-ec2f-468b-9140-4452f532a8c8",
      //   "startTime": "2024-01-05T11:20:00.000Z",
      //   "clinicId": "7DUCVwiqwirnb5L4eev1"
      // }

      bookAppointment.mutate(
        {
          bookingData,
        },
        {
          onSuccess: () => {
            setEventProps({
              event: {
                action: Actions.CompletedBooking,
                city: queries.city,
                clinic: queries.clinicId,
                daysUntil: personalDetails.date
                  ? getDaysUntil(personalDetails.date)
                  : undefined,
                partner: queries.partner,
                service: queries.service,
                speciality: queries.speciality,
                vertical: queries.vertical,
                practitionerId: bookingData.practitionerId,
              },
            });

            advanceStep();
          },
          onError: (error) => {
            const errorMsg = _get(
              error,
              'response.data.message',
              error?.response?.data,
            ) as string;

            if (errorMsg.includes('in the past')) {
              // convert dynamic error like '2024-01-0511:20:00.000Z is in the past' to static error
              setError('Appointment in past');
            } else setError(errorMsg);
            Sentry.captureMessage(errorMsg);
          },
        },
      );
    }
  }

  useEffect(() => {
    if (error !== '') {
      setEventProps({
        event: {
          action: Actions.PhoneVerificationError,
          message: error,
        },
        target: {
          posthog: true,
        },
      });
    }
  }, [error]);

  const contactSupport = () => {
    return (
      <>
        {t('steps.verify.error.error_persists', { ns: 'booking' })}
        <u>
          <a
            href={t('steps.verify.error.support_url', { ns: 'booking' })}
            onClick={() => {
              sentToPosthog('contact_customer_service');
            }}
          >
            {t('steps.verify.error.contact_support', { ns: 'booking' })}
          </a>
        </u>
      </>
    );
  };

  const sentToPosthog = (message: string) => {
    setEventProps({
      event: {
        action: Actions.PhoneVerificationSupport,
        message: message,
      },
      target: {
        posthog: true,
      },
    });
  };

  return (
    <>
      {/* Error message when backend returns status code 400 */}
      {error && error === 'No response recieved' && (
        <div className={`mb-4 w-full rounded-lg bg-[#FFEBD2] p-4`}>
          <p className="text-black">
            {errorMessages.find((msg) => msg.error === error)?.message}{' '}
            {contactSupport()}
          </p>
        </div>
      )}
      <StepWrapper
        title={t('steps.verify.title', { ns: 'booking' })}
        stepWidth="max-w-[446px]"
      >
        <form onSubmit={verifyPhone}>
          <div className="flex w-full flex-col items-center">
            <div className="flex items-center justify-center pb-8">
              <p className="text-center font-normal">
                {t('steps.verify.heading')}
                <span className="ph-no-capture pl-2 font-medium">
                  {formattedPhoneNumber}
                </span>
              </p>
            </div>

            <div className="flex w-full flex-col gap-2">
              <input
                name={code}
                placeholder={t('steps.verify.placeholder', { ns: 'booking' })}
                value={code}
                onChange={(e) => setCode(e.target.value)}
                className="input border-accent focus-input w-full rounded-lg border-2 text-center text-black"
                maxLength={6}
                //disabled={initiate.isError}
                type="tel"
                autoFocus
                autoComplete="one-time-code"
                data-testid="verify-code-input"
              />
            </div>
            {/* Error message for most errors */}
            {error &&
              error !== 'No response recieved' &&
              errorMessages.find((msg) => msg.error === error) && (
                <div className={`mt-2 w-full rounded-lg bg-[#FFE2DF] p-4`}>
                  <p className="text-black">
                    {errorMessages.find((msg) => msg.error === error)?.message}
                  </p>
                </div>
              )}
            {/* Error message when unknown error occurs */}
            {error && !errorMessages.find((msg) => msg.error === error) && (
              <div className={`mt-2 w-full rounded-lg bg-[#FFE2DF] p-4`}>
                <p className="text-black">
                  {t('steps.verify.error.unknown_error', { ns: 'booking' })}{' '}
                  {contactSupport()}
                </p>
              </div>
            )}
            {/* Errors which require a new time slot selection */}
            {isRebookingRequired(error) && (
              <Button
                onClick={() => {
                  sentToPosthog('button_select_new_time');
                  goToStep(currStep - 2);
                }}
                appearance="accent"
                className="my-2 w-full font-normal normal-case"
              >
                {t('steps.verify.button_new_time', { ns: 'booking' })}
              </Button>
            )}
            {!isRebookingRequired(error) && (
              <div className="mt-2 w-full rounded-lg bg-black/10 p-4 text-sm">
                <p className="flex flex-col justify-center gap-2 text-center md:justify-start md:text-left">
                  <span className={classNames('flex gap-2 md:justify-start')}>
                    <button
                      className={classNames(
                        { 'text-white/20': resendDisabled },
                        'underline',
                      )}
                      onClick={initiateVerification}
                      disabled={resendDisabled}
                    >
                      {t('steps.verify.resend')}
                    </button>
                    {resendDisabled && (
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 20 20"
                        className="w-6 -rotate-90"
                      >
                        <circle
                          className="stroke-secondary confirm-loading"
                          fill="none"
                          cx="10"
                          cy="10"
                          r="5"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                        <circle
                          className="stroke-white/10"
                          fill="none"
                          cx="10"
                          cy="10"
                          r="5"
                          strokeWidth="2"
                          strokeDasharray="32"
                          strokeDashoffset="0"
                          strokeLinecap="round"
                        />
                      </svg>
                    )}
                  </span>
                </p>
              </div>
            )}

            {(initiate.isLoading ||
              verify.isLoading ||
              bookAppointment.isLoading) && <SpinnerWrapper />}
          </div>
        </form>
      </StepWrapper>
    </>
  );
}
