import { localesDateOnly } from '@booking/shared';
import { Locales } from '@booking/types';
import { StepButton } from '@booking/ui-web';
import { Icon } from '@drdropin-tech/theseus';
import { formatRelative } from 'date-fns';
import { useTranslation } from 'next-i18next';

type Props = {
  nextAvailableDate: string; // format: yyyy-mm-dd
  locale: Locales;
  onClick: () => void;
};

export default function NextAvailableDateButton({
  nextAvailableDate,
  locale,
  onClick,
}: Props) {
  const { t } = useTranslation(['booking']);

  return (
    <>
      <StepButton
        onClick={onClick}
        title={
          <>
            <span className="flex items-center justify-between ">
              <span>
                <span className="text-sm italic">
                  {t('steps.select_datetime.next_available_time')}
                </span>

                <span className="pl-2 font-medium">
                  {formatRelative(new Date(nextAvailableDate), new Date(), {
                    locale: localesDateOnly[locale],
                  })}
                </span>
              </span>
              <Icon name="ChevronRight" />
            </span>
          </>
        }
      />
    </>
  );
}
