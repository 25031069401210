import classNames from 'classnames';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';

type DateScrollButtonsProps = {
  forward: () => void;
  backward: () => void;
  canGoBack: boolean;
};

export default function DateButtonsScroll({
  forward,
  backward,
  canGoBack,
}: DateScrollButtonsProps) {
  return (
    <div className="mt-2 flex items-center justify-center rounded-lg">
      <button
        onClick={backward}
        className={classNames(
          {
            'btn-disabled bg-white/20': !canGoBack,
            'bg-white': canGoBack,
          },
          'hover:bg-accent rounded-lg rounded-r-none border-none p-2 transition-all duration-200',
        )}
        disabled={!canGoBack}
        data-testid="date-scroll-backward"
      >
        <FiArrowLeft className="stroke-primary" size={24} />
      </button>
      <button
        onClick={forward}
        className={classNames(
          'hover:bg-accent rounded-lg rounded-l-none border-none bg-white p-2 transition-all duration-200',
        )}
        data-testid="date-scroll-forward"
      >
        <FiArrowRight className="stroke-primary" size={24} />
      </button>
    </div>
  );
}
