import { CheckBox } from '@drdropin-tech/theseus';

import { useModal } from '../providers/ModalProvider';

interface Props {
  checked: boolean;
  onChange: (value: boolean) => void;
  i18nFunction: (key: string) => string;
}

export default function TermsOfService(props: Props) {
  const t = props.i18nFunction;
  const { openModal } = useModal();

  function toggleModal() {
    openModal({
      content: {
        title: t('terms.modal_title'),
        iframeSrc: t('terms.modal_content'),
      },
    });
  }

  return (
    <div className="rounded-lg bg-black/20 px-4 py-6">
      <p className="text-sm">{t('terms.accept_text')}</p>
      <br />
      <u onClick={toggleModal}>{t('terms.terms')}</u>

      <CheckBox
        label={t('terms.accept')}
        checked={props.checked}
        onChange={() => props.onChange(!props.checked)}
        theme="general"
        classname="border-white"
      />
    </div>
  );
}
