import {
  ALL_STEPS,
  getAllParams,
  getStepFilledToNow,
  useNavigationStore,
} from '@booking/shared';
import _isEmpty from 'lodash/isEmpty';
import { NextRouter, useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import { useQueryParams } from 'use-query-params';
import { shallow } from 'zustand/shallow';

import ReceiptStep from './ReceiptPageStep';
import SelectClinicStep from './SelectClinicStep';
import SelectDateAndTime from './SelectDateAndTime';
import SelectLocationStep from './SelectLocationStep';
import SelectPersonalDetailsFormStep from './SelectPersonalDetails';
import SelectPractitionerStep from './SelectPractitionerStep';
import SelectSpeciality from './SelectSpeciality';
import SelectVerticalStep from './SelectVerticalStep';
import VerificationStep from './VerificationStep';

export const STEPS = [
  SelectVerticalStep,
  SelectSpeciality,
  SelectLocationStep,
  SelectClinicStep,
  SelectPractitionerStep,
  SelectDateAndTime,
  SelectPersonalDetailsFormStep,
  VerificationStep,
  ReceiptStep,
];

export const stepCount = STEPS.length;

type StepProps = {
  router: NextRouter;
};

export default function Step({ router }: StepProps) {
  const { queries, currStep, goToStep, setCurrentStep, isInit, setIsInit } =
    useNavigationStore(
      (state) => ({
        queries: state.queries,
        currStep: state.currStep,
        goToStep: state.goToStep,
        isInit: state.isInit,
        setIsInit: state.setIsInit,
        setCurrentStep: state.setCurrentStep,
      }),
      shallow,
    );
  const allQueryParams = useMemo(() => getAllParams(), []);
  const [_, setParam] = useQueryParams(allQueryParams);
  const { isReady, query } = useRouter();

  useEffect(() => {
    // console.log('FIRST LOAD - index', currStep)
    if (!isReady || currStep > 0) return;
    const stepsTilNow = getStepFilledToNow(ALL_STEPS, query);

    if (stepsTilNow > 0) {
      goToStep(stepsTilNow);
    }

    const hasMembershipStorageSession = sessionStorage.getItem(
      'membershipCampaigns',
    );

    if (hasMembershipStorageSession && queries.partnerId === undefined) {
      sessionStorage.removeItem('membershipCampaigns');
    }

    setIsInit(isReady);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady]);

  useEffect(() => {
    if (_isEmpty(queries) && !isInit) return;
    setParam(queries, 'replace');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queries]);

  useEffect(() => {
    // Fetch top-banner dom element
    const banner = document.getElementById('feedback-banner');
    // Conditions:
    // 1. If top-banner (not daniel's banner) is present
    // 2. If #1, see if user has scrolled beyond the banner already.
    //    If so, don't scroll to the top, but rather to the end of the banner.
    //    This keeps the banner in DOM, accessible to the user.
    //
    //    If not 2 or 1, scroll to top of page.
    if (banner) {
      const bannerOffset = banner.offsetHeight;
      if (window.scrollY >= bannerOffset) {
        window.scrollTo(0, bannerOffset);
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [currStep]);

  useEffect(() => {
    router.beforePopState(({ as }) => {
      // I only want to allow this / these routes!
      if (as !== '/') {
        if (currStep > 0) {
          setCurrentStep(currStep - 1);
        }
        return true;
      }

      return false;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  // Don't render anything, until flow is initialized
  if (!isInit) return null;
  const Component = STEPS[currStep];
  return <Component />;
}
