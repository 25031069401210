import { formatPrice, type SurchargeType } from '@booking/shared';
import { Icon } from '@drdropin-tech/theseus';
import { useTranslation } from 'react-i18next';

export default function SurchargePricingInfo({
  price,
  surcharge,
  surchargeType,
  showIcon = true,
  className = '',
}: {
  price: number;
  surcharge: number;
  surchargeType?: SurchargeType;
  showIcon?: boolean;
  className?: string;
}) {
  const { t } = useTranslation('booking');
  return (
    <div className="flex items-center gap-2 pb-2">
      {showIcon ? <Icon name="Info" /> : null}
      <span className={className}>
        {t(`steps.select_datetime.surcharge.format`, {
          surchargeType: t(`steps.select_datetime.surcharge.${surchargeType}`),
          surcharge: formatPrice(surcharge),
          totalPrice: formatPrice(price + surcharge),
        })}
      </span>
    </div>
  );
}
