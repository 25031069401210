import { Locales, ServiceTreeLink } from '@booking/types';
import { HighlightedIcon, StepListItem } from '@booking/ui-web';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';

type Props = {
  index: number;
  link: ServiceTreeLink;
  icon: boolean;
  rounded: boolean;
};
export default function LinkButton({ index, link, icon, rounded }: Props) {
  const locale = useRouter().locale as Locales;

  function onClick(url: string): void {
    window.open(url, '_self');
  }

  const animatedStepVariants = (i: number) => ({
    hidden: { opacity: 0, translateY: '5%' },
    show: {
      opacity: 1,
      translateY: '0%',
      transition: {
        delay: i * 0.07,
        duration: 0.3,
      },
    },
  });

  return (
    <motion.div
      variants={animatedStepVariants(index)}
      initial="hidden"
      animate="show"
      layout
    >
      <StepListItem
        key={link.id}
        className={`bg-white text-black ${rounded ? 'rounded-xl' : ''}`}
        onClick={() => onClick(link.url)}
      >
        <div className="flex w-full items-center justify-between">
          <div className="flex-col text-left">
            <p className="text-sm">{link.title[locale]}</p>
            {link.description[locale] && (
              <p className="mr-2 text-xs">{link.description[locale]}</p>
            )}
          </div>

          <div className="flex items-center">
            <p className="text-sm">{link.additionalInfo[locale]}</p>
            {icon && <HighlightedIcon icon="ChevronRight" />}
          </div>
        </div>
      </StepListItem>
    </motion.div>
  );
}
