type Props = {
  onClick: () => void;
  text: string;
};

export default function TextButton({ onClick, text }: Props) {
  return (
    <div
      className="cursor-pointer text-sm underline lg:text-[17px]"
      onClick={onClick}
    >
      {text}
    </div>
  );
}
