import { Practitioners } from '@booking/api';
import { getPractitionersWithSortedTimeSlotsForSelectedDate } from '@booking/shared';
import { Option, TimeSlot } from '@booking/types';
import { SelectPills, SpinnerWrapper } from '@booking/ui-web';
import useNextAvailableByPractitioner from '@hooks/useNextAvailable';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';
import { useMemo, useRef, useState } from 'react';

import NoAvailableTimeSlotsBanner from './NoAvailableSlotsBanner';
import PractitionerTimePicker from './PractitionerTimePicker';

type SortKey = 'by-availability' | 'by-name';
type SortMethods = {
  [key in SortKey]: (
    a: {
      name: string;
      timeSlots: TimeSlot[];
      nextAvailableTimeSlot?: { startTime: string };
    },
    b: {
      name: string;
      timeSlots: TimeSlot[];
      nextAvailableTimeSlot?: { startTime: string };
    },
  ) => number;
};

const sortMethod: SortMethods = {
  'by-availability': (
    a: { timeSlots: TimeSlot[]; nextAvailableTimeSlot?: { startTime: string } },
    b: { timeSlots: TimeSlot[]; nextAvailableTimeSlot?: { startTime: string } },
  ) => {
    if (b.timeSlots.length - a.timeSlots.length !== 0) {
      return b.timeSlots.length - a.timeSlots.length;
    }
    if (a.nextAvailableTimeSlot && b.nextAvailableTimeSlot) {
      return (
        new Date(a.nextAvailableTimeSlot.startTime).getTime() -
        new Date(b.nextAvailableTimeSlot.startTime).getTime()
      );
    }
    if (a.nextAvailableTimeSlot) {
      return -1;
    }
    return 1;
  },

  'by-name': (a: { name: string }, b: { name: string }) =>
    a.name.localeCompare(b.name),
};

type Props = {
  clinicId: string;
  timeSlots: TimeSlot[];
  selectedDate: string;
  isLoading: boolean;
  serviceId: string;
  daysAhead: number;
  setSelectedDate: (date: string) => void;
  onClick: (
    time?: string,
    practitionerId?: string,
    practitionerName?: string,
  ) => void;
  price?: number;
};

export default function PractitionerTimePickerList({
  clinicId,
  serviceId,
  timeSlots,
  selectedDate,
  isLoading,
  daysAhead,
  setSelectedDate,
  onClick,
  price,
}: Props) {
  const { t } = useTranslation('booking');

  const [nextAvailableInDaysAhead, setNextAvailableInDaysAhead] = useState<
    Record<string, { startTime: string }>
  >({});

  const practitionersWithoutNextAvailableInDaysAhead = useRef<string[]>([]);

  const sortOptions: Option<SortKey>[] = [
    {
      key: t('steps.select_datetime.sort.options.most_available'),
      value: 'by-availability',
    },
    {
      key: t('steps.select_datetime.sort.options.by_name'),
      value: 'by-name',
    },
  ];

  const [selectedOption, setSelectedOption] = useState(sortOptions[0]);

  const { data: practitioners } = useQuery(
    ['practitioners', serviceId, clinicId],
    ({ signal }) =>
      Practitioners.getPractitioners({
        serviceId,
        clinicId,
        signal,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: !!serviceId && !!clinicId,
    },
  );

  const practitionersWithTimeSlotsForSelectedDate = useMemo(() => {
    const practitionersWithTimeSlotsForSelectedDate =
      getPractitionersWithSortedTimeSlotsForSelectedDate({
        timeSlots,
        selectedDate,
        practitioners: practitioners || [],
      });
    const nextAvailableInDaysAhead =
      practitionersWithTimeSlotsForSelectedDate.reduce(
        (acc: Record<string, { startTime: string }>, practitioner) => {
          if (practitioner.nextAvailableTimeSlotInDaysAhead) {
            acc[practitioner.id] =
              practitioner.nextAvailableTimeSlotInDaysAhead;
          }
          return acc;
        },
        {},
      );
    practitionersWithoutNextAvailableInDaysAhead.current =
      practitionersWithTimeSlotsForSelectedDate
        .filter(
          (practitioner) => !practitioner.nextAvailableTimeSlotInDaysAhead,
        )
        .map((practitioner) => practitioner.id);

    setNextAvailableInDaysAhead(nextAvailableInDaysAhead);
    return practitionersWithTimeSlotsForSelectedDate;
  }, [practitioners, selectedDate, timeSlots]);

  // A useEffect for fetching nextAvailable data for practitioners that do not have their nextAvailable in daysAhead
  const fetchedNextAvailable = useNextAvailableByPractitioner(
    practitionersWithoutNextAvailableInDaysAhead.current,
    serviceId,
    clinicId,
    selectedDate + 'T00:00:00Z',
  );

  const sortedPractitionersWithTimeslots = useMemo(() => {
    return practitionersWithTimeSlotsForSelectedDate
      .map((practitioner) => {
        return {
          ...practitioner,
          nextAvailableTimeSlot:
            nextAvailableInDaysAhead[practitioner.id] ||
            fetchedNextAvailable[practitioner.id],
        };
      })
      .sort(sortMethod[selectedOption.value]);
  }, [
    practitionersWithTimeSlotsForSelectedDate,
    selectedOption.value,
    fetchedNextAvailable,
    nextAvailableInDaysAhead,
  ]);

  const changeOption = (option: Option<SortKey>) => {
    setSelectedOption(option);
  };

  return isLoading ? (
    <SpinnerWrapper />
  ) : (
    <div className="flex flex-col gap-16">
      <div className="flex">
        <SelectPills
          options={sortOptions}
          selectedOption={selectedOption}
          onClick={changeOption}
        />
      </div>
      {sortedPractitionersWithTimeslots.length === 0 ? (
        <NoAvailableTimeSlotsBanner />
      ) : (
        sortedPractitionersWithTimeslots?.map((practitioner) => {
          return (
            <PractitionerTimePicker
              key={practitioner.id}
              practitioner={practitioner}
              timeSlotsInSelectedDate={practitioner.timeSlots}
              nextAvailable={practitioner.nextAvailableTimeSlot}
              selectedDate={selectedDate}
              isLoading={isLoading}
              daysAhead={daysAhead}
              setSelectedDate={setSelectedDate}
              onClick={onClick}
              price={price}
            />
          );
        })
      )}
    </div>
  );
}
