// Config related to speciality step item
//

const specialityConfig = {
  modals: {
    dermatologist: {
      title: 'modals.dermatologist_video_consultation.title',
      description: 'modals.dermatologist_video_consultation.description',
    },
    covid: {
      title: 'modals.covid_video_consultation.title',
      description: 'modals.covid_video_consultation.description',
    },
    fysio: {
      title: 'modals.physio_video_consultation.title',
      description: 'modals.physio_video_consultation.description',
    },
    osteo: {
      title: 'modals.physio_video_consultation.title',
      description: 'modals.physio_video_consultation.description',
    },
    default: {
      title: 'modals.general_video_consultation.title',
      description: 'modals.general_video_consultation.description',
    },
  },
};

export default specialityConfig;
