import classNames from 'classnames';
import Image from 'next/image';
import { ReactNode } from 'react';

import SpinnerWrapper from './SpinnerWrapper';

type Props = {
  stepWidth: string;
  title?: string;
  subTitle?: string;
  imageSrc?: string;
  isLoading?: boolean;
  className?: string;
  children: ReactNode | ReactNode[];
};

export default function StepWrapper({
  title,
  subTitle,
  imageSrc,
  isLoading,
  className,
  stepWidth,
  children,
}: Props) {
  return (
    <div
      className={classNames(
        'm-auto mb-20 flex flex-col items-center justify-center',
        stepWidth,
      )}
    >
      {imageSrc && (
        <div className="mb-8">
          <Image
            src={imageSrc}
            width={112}
            height={112}
            alt="Step image"
            style={{
              maxWidth: '100%',
              height: 'auto',
              objectFit: 'cover',
            }}
          />
        </div>
      )}

      <div className="prose prose-h2:text-white mb-10 text-center">
        {title && (
          <h2 className="leading-[44px] md:text-[40px] lg:leading-[56px]">
            {title}
          </h2>
        )}

        {subTitle && (
          <p className="text-xl font-normal leading-7">{subTitle}</p>
        )}
      </div>

      <div
        className={classNames(
          'flex w-full flex-col items-center justify-center gap-2',
          className,
        )}
      >
        {isLoading ? <SpinnerWrapper /> : children}
      </div>
    </div>
  );
}
