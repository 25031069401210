import classNames from 'classnames';

type ButtonBadgeProps = {
  title: string;
  className?: string;
};

export default function ButtonBadge({ title, className }: ButtonBadgeProps) {
  return (
    <div
      className={classNames(
        'bg-warning-light absolute -right-2 -top-1.5 w-fit rounded-full px-3 py-1 text-center text-xs shadow-sm transition-all',
        className,
      )}
    >
      {title}
    </div>
  );
}
