type Props = {
  onClick: () => void;
  title: string;
};

export default function RoundedButton({ onClick, title }: Props) {
  return (
    <button
      className="flex w-fit rounded-full bg-white px-5 py-1 text-black"
      onClick={onClick}
    >
      {title}
    </button>
  );
}
