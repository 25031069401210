import { useNavigationStore } from '@booking/shared';
import { Vertical } from '@booking/types';
import { Notification } from '@booking/ui-web';
import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import { ReactNode } from 'react';

const BookingNav = dynamic(() => import('@layouts/BookingNav'), { ssr: false });
const Banner = dynamic(() => import('@components/Banner'), { ssr: false });

type Props = {
  children: ReactNode | ReactNode[];
};

export default function BookingPageWrap({ children }: Props) {
  const { queries } = useNavigationStore((state) => ({
    queries: state.queries,
  }));
  const { vertical = 'general' } = queries;
  const { t } = useTranslation('common');
  return (
    <>
      <div
        className="bg-primary w-full text-white antialiased"
        data-theme={getVerticalName(vertical)}
      >
        <Banner />
        <Notification i18nFunction={t} />
        <div className="flex min-h-screen flex-col md:min-h-0">
          <BookingNav />
          <div className="booking-wrapper mx-auto w-full px-5 pt-5">
            {children}
          </div>
        </div>
      </div>
    </>
  );
}

const getVerticalName = (vertical: Vertical) => {
  switch (vertical) {
    case 'mental-health':
      return 'psychology';
    case 'dermatologist':
      return 'specialist';
    case 'gynecologist':
      return 'specialist';
    case 'eye-doctor':
      return 'specialist';
    default:
      return vertical;
  }
};
