import classNames from 'classnames';
import { motion } from 'framer-motion';
import { ReactNode } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Props<T = Record<string, any>> = {
  onClick: (choice: T) => void;
  children?: ReactNode | ReactNode[];
  className?: string;
  disabled?: boolean;
};

const stepVariant = {
  hide: { opacity: 0, y: 10 },
  show: { opacity: 1, y: 0 },
};

export default function StepListItem({
  children,
  className,
  disabled,
  onClick,
}: Props) {
  return (
    <motion.button
      variants={stepVariant}
      disabled={disabled}
      className={classNames(
        'hover:bg-accent flex w-full cursor-pointer items-center justify-between gap-1 px-6 py-5 transition-all',
        className,
      )}
      onClick={onClick}
      data-testid="step-list-item"
    >
      {children}
    </motion.button>
  );
}
