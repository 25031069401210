import { ReactElement } from 'react';

type Renderable = number | string | ReactElement | Renderable[];

export type Children<T> = ((options: T) => Renderable) | Renderable;

export default function renderChildrenWithProps<T>(
  children: Children<T>,
  props: T,
) {
  if (typeof children === 'function') return children(props);
  return children;
}
