import classNames from 'classnames';
import { CountryCode, E164Number } from 'libphonenumber-js/types';
import { FocusEvent, useRef } from 'react';
import { FieldError } from 'react-hook-form';
import { getCountries } from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input/input';

import InputError from '../InputError';
import CountrySelect from './CountrySelect';

type PhoneInputProps = {
  label: string;
  defaultCountry: CountryCode;
  name: string;
  value?: E164Number;
  selectedCountry: CountryCode;
  isDirty: boolean;
  errors: FieldError | undefined;
  onChange: (value: E164Number) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  changeSelectedCountry: (country: CountryCode) => void;
  i18nFunction: (key: string) => string;
};

export default function CustomPhoneInput({
  label,
  defaultCountry,
  name,
  value,
  onChange,
  onBlur,
  selectedCountry,
  changeSelectedCountry,
  isDirty,
  errors,
  i18nFunction,
}: PhoneInputProps) {
  const phoneInputRef = useRef<HTMLInputElement>(null);

  const countries = getCountries();

  const changeCountry = (country: CountryCode) => {
    changeSelectedCountry(country);
    phoneInputRef.current?.focus();
  };

  return (
    <div className="flex w-full flex-col gap-2">
      <label htmlFor={name}>{label}</label>
      <div className="flex flex-wrap gap-2">
        <CountrySelect
          name={name}
          selected={selectedCountry}
          options={countries}
          onChange={changeCountry}
          i18nFunction={i18nFunction}
        />
        <div className="relative w-full flex-1 gap-1">
          <PhoneInput
            name={name}
            defaultCountry={defaultCountry}
            country={selectedCountry}
            className={classNames(
              { 'focus-input-error': errors?.message },
              'input border-accent focus-input w-full rounded-lg border-2 text-black focus:ring-offset-white',
            )}
            value={value}
            onChange={onChange}
            ref={phoneInputRef}
            onBlur={onBlur}
            data-testid="phone"
          />

          <InputError isDirty={isDirty} errors={errors} />
        </div>
        {errors?.message && (
          <div className="w-full rounded-lg bg-[#FFE2E0] p-3 text-xs leading-6 text-black">
            {errors?.message}
          </div>
        )}
      </div>
    </div>
  );
}

CustomPhoneInput.displayName = 'CustomPhoneInput';
