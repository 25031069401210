import { Icon } from '@drdropin-tech/theseus';
import classNames from 'classnames';

type Option<T> = {
  icon?: string;
  key: string;
  value: T;
};

type Props<T> = {
  options: Option<T>[];
  selectedOption: Option<T>;
  onClick: (value: Option<T>) => void;
  isDisabled?: (key: T) => boolean;
};

export default function SelectPills<T>({
  options,
  selectedOption,
  onClick,
  isDisabled,
}: Props<T>) {
  return (
    <div className="mb-4 flex w-full items-center gap-2">
      <div className="relative flex gap-2">
        <ul className="flex gap-2">
          {options.map((option, index) => {
            return (
              <li
                tabIndex={index}
                key={option.key}
                className={classNames(
                  {
                    'bg-accent text-black': option.key === selectedOption.key,
                  },
                  isDisabled && {
                    'disabled pointer-events-none opacity-50': isDisabled(
                      option.value,
                    ),
                  },
                  'border-accent flex cursor-pointer snap-start items-center gap-2 rounded-full border px-4 py-2 text-xs',
                )}
                data-testid={`select-pill-${option.value}`}
                onClick={() => onClick(option)}
              >
                {option.icon && <Icon name={option.icon} iconSize="xs" />}
                {option.key}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
