import { Appointments, Clinics } from '@booking/api';
import {
  getDateInTimezone,
  parseDay,
  timeZone,
  useBannerStore,
  useClinicStore,
  useCookieStore,
  useDateStore,
  useNavigationStore,
  usePersonalDataStore,
  useServiceTreeStore,
  useWeekRanges,
} from '@booking/shared';
import { Actions } from '@booking/types';
import { StepWrapper } from '@booking/ui-web';
import BookingSummary from '@components/BookingSummary';
import DatePicker from '@components/DatePicker';
import { TimePicker } from '@components/TimePicker';
import PractitionerTimePickerList from '@components/TimePicker/PractitionerTimePickerList';
import useAnalytics from '@hooks/useAnalytics';
import { useQuery } from '@tanstack/react-query';
import { addDays } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { useTranslation } from 'next-i18next';
import posthog from 'posthog-js';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { shallow } from 'zustand/shallow';

export default function SelectDateAndTime() {
  const setEventProps = useAnalytics();

  const { t } = useTranslation('booking');
  const { selectedDate, setSelectedDate } = useDateStore()((state) => ({
    selectedDate: state.selectedDate,
    setSelectedDate: state.setSelectedDate,
  }));

  const isMobile = useMediaQuery({ maxWidth: '768px' });
  const daysAhead = isMobile ? 5 : 7;
  const [startDate, setStartDate] = useState(getDateInTimezone());
  const [endDate, setEndDate] = useState(
    getDateInTimezone(addDays(parseDay(startDate), daysAhead - 1)),
  );

  const {
    next: nextWeek,
    prev: prevWeek,
    weeks,
    currentWeekIndex,
    jumpToDate,
    canGoBack,
  } = useWeekRanges({
    daysAhead,
    selectedDay: selectedDate,
    endDate: parseDay(endDate),
    startDate: parseDay(startDate),
    setSelectedDate: (day) => setSelectedDate(day),
    setStartDate: (day) => setStartDate(getDateInTimezone(day)),
    setEndDate: (day) => setEndDate(getDateInTimezone(day)),
  });

  const { queries, advanceStep, setQueries } = useNavigationStore(
    (state) => ({
      setQueries: state.setQueries,
      queries: state.queries,
      advanceStep: state.advanceStep,
    }),
    shallow,
  );
  const setPersonalDetails = usePersonalDataStore()(
    (state) => state.setPersonalDetails,
  );
  const { clinic, setClinic } = useClinicStore()((state) => ({
    clinic: state.clinic,
    setClinic: state.setClinic,
  }));

  const { getServiceByShortId } = useServiceTreeStore(
    (state) => ({
      getServiceByShortId: state.getServiceByShortId,
    }),
    shallow,
  );

  const service = getServiceByShortId(queries.service);
  const serviceUuid = service?.id ?? '';

  const openBanner = useBannerStore((state) => state.openBanner);

  useQuery(
    ['clinics', serviceUuid, queries.city, queries.clinicId],
    ({ signal }) =>
      Clinics.getClinics({
        serviceId: serviceUuid,
        city: queries.city,
        signal,
      }),
    {
      enabled: !!serviceUuid && !!queries.city,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        data.filter((clinic) => {
          if (clinic.shortId === queries.clinicId) {
            setClinic(clinic);
          }
        });
      },
    },
  );

  /*
   * Get timeSlots
   */
  const {
    data: timeSlots,
    isFetching,
    isLoading,
  } = useQuery(
    ['timeSlots', serviceUuid, clinic?.id, startDate, endDate],
    ({ signal }) =>
      Appointments.getTimeSlots({
        serviceId: serviceUuid,
        clinicId: clinic?.id,
        fromDate: `${startDate}T00:00:00.000Z`,
        toDate: `${endDate}T23:59:59.000Z`,
        practitionerId: queries.practitionerId,
        signal,
      }),
    {
      enabled: !!serviceUuid && !!clinic?.id,
      refetchOnWindowFocus: false,
      select: (data) => {
        return data;
      },
    },
  );

  /*
   * If there are no available time slots for the first day, set selected day
   * to the first day with time slots available
   */
  useEffect(() => {
    if (timeSlots) {
      const firstAvailableTimeSlot = timeSlots.find(
        (timeSlot) => timeSlot.isAvailable,
      );
      setSelectedDate(
        firstAvailableTimeSlot?.startTime.split('T')[0] || selectedDate,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeSlots]);

  const handleOnClick = (time?: string, practitionerId?: string) => {
    if (practitionerId) {
      setQueries({ practitionerId });
    }

    setPersonalDetails({
      date: selectedDate,
      time,
      practitionerId,
    });

    setEventProps({
      event: {
        action: Actions.SelectTime,
        vertical: queries.vertical,
        speciality: queries.speciality,
        service: queries.service,
        clinic: clinic?.shortId,
        time,
        practitionerId,
      },
    });

    advanceStep();
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      openBanner();
    }, 20 * 1000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const price = service?.price;

  // In case the user is in another timezone, we should show them the comparable time relevant
  // to available booking time slots.
  const patientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const isPosthogEnabled = useCookieStore((state) => state.isPosthogEnabled);

  if (!clinic) return null;

  const fysFirstAvailibleWithoutPractitionerABTest =
    queries.service === 'physical-first-available' &&
    isPosthogEnabled &&
    posthog?.getFeatureFlag('physical-first-available-without-practitioner') ===
      'test';

  const bookByPractitioner = fysFirstAvailibleWithoutPractitionerABTest
    ? false
    : service?.bookByPractitioner;

  return (
    <StepWrapper title={t('steps.select_datetime.title')} stepWidth="max-w-2xl">
      <div className="w-full md:max-w-[512px]">
        <BookingSummary
          hasClinic={true}
          hasTimeStamp={false}
          practitionerId={queries.practitionerId}
          currentStep="SelectDateAndTime"
        />

        <p className="flex justify-between text-sm">
          {patientTimezone !== timeZone ? (
            <CurrentTime patientTimezone={patientTimezone} />
          ) : null}
        </p>

        <DatePicker
          selectedDate={selectedDate}
          timeSlots={timeSlots || []}
          setSelectedDate={setSelectedDate}
          weeks={weeks}
          currentWeekIndex={currentWeekIndex}
          next={nextWeek}
          prev={prevWeek}
          isLoading={isLoading}
          canGoBack={canGoBack}
        />
      </div>

      <div className="mt-4 flex w-full flex-col gap-8">
        <div className="w-full">
          {bookByPractitioner && !queries.practitionerId ? (
            <div className="w-full">
              <PractitionerTimePickerList
                clinicId={clinic?.id}
                timeSlots={timeSlots || []}
                selectedDate={selectedDate}
                isLoading={isFetching}
                serviceId={serviceUuid!}
                daysAhead={daysAhead}
                setSelectedDate={jumpToDate}
                onClick={(time, practitionerId) => {
                  handleOnClick(time, practitionerId);
                }}
                price={price}
              />
            </div>
          ) : (
            <div className="m-auto w-full max-w-[512px]">
              <TimePicker
                timeSlots={timeSlots || []}
                selectedDate={selectedDate}
                isLoading={isFetching}
                daysAhead={daysAhead}
                onClick={(time, practitionerId) => {
                  handleOnClick(time, practitionerId);
                }}
                practitionerId={queries.practitionerId}
                price={price}
              />
            </div>
          )}
        </div>
      </div>
    </StepWrapper>
  );
}

/**
 * Component for displaying the current time related to available appointments.
 * Will display the current time in the timezone set in booking env vars.
 *
 *  Could later be used to display the current time for the selected clinic, in case
 *  that use case arrives in the future.
 *
 *
 * @param patientTimezone - The timezone of the current user browser
 * @returns The current time in the patient's timezone in a span
 */
const CurrentTime = ({ patientTimezone }: { patientTimezone: string }) => {
  const { t } = useTranslation('booking');
  const [currTimeInNorway, setCurrTimeInNorway] = useState(
    formatInTimeZone(new Date(), 'Europe/Oslo', 'HH:mm'),
  );
  useEffect(() => {
    if (patientTimezone == timeZone) {
      return;
    }
    const interval = setInterval(() => {
      setCurrTimeInNorway(formatInTimeZone(new Date(), 'Europe/Oslo', 'HH:mm'));
    }, 60 * 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <span>
      {t('steps.select_datetime.current_time_in_clinics')}
      {currTimeInNorway}
    </span>
  );
};
