import { Actions } from '@booking/types';
import Image from 'next/image';

import getMobileOS from '../utils/getMobileOS';

type Props = {
  trackingFunction?: (actionName: Actions) => void;
};

export default function AppLinks({ trackingFunction }: Props) {
  const mobileOS = getMobileOS();

  function trackAppStoreClick() {
    if (trackingFunction) {
      trackingFunction(Actions.ClickAppStore);
    }
  }

  function trackPlayStoreClick() {
    if (trackingFunction) {
      trackingFunction(Actions.ClickPlayStore);
    }
  }

  return (
    <div className="flex justify-around gap-4">
      {['iOS', 'unknown'].includes(mobileOS) ? (
        <a
          href="https://apps.apple.com/no/app/dr-dropin/id1467608455?ls=1"
          className="h-15 relative w-full md:w-1/2 md:max-w-[200px]"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => trackAppStoreClick()}
        >
          <Image
            src="/illustrations/appStoreiOS.png"
            alt="Download on the App Store"
            fill
            sizes="100vw"
            style={{
              objectFit: 'contain',
            }}
          />
        </a>
      ) : null}
      {['Android', 'unknown'].includes(mobileOS) ? (
        <a
          href="https://play.google.com/store/apps/details?id=com.drdropin"
          target="_blank"
          rel="noopener noreferrer"
          className="h-15 relative w-full md:w-1/2 md:max-w-[200px]"
          onClick={() => trackPlayStoreClick()}
        >
          <Image
            src="/illustrations/appStoreAndroid.png"
            alt="Get it on Google Play"
            fill
            sizes="100vw"
            style={{
              objectFit: 'contain',
            }}
          />
        </a>
      ) : null}
    </div>
  );
}
