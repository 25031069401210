import { useNotificationStore } from '@booking/shared';
import { Icon } from '@drdropin-tech/theseus';
import { motion } from 'framer-motion';

const variants = {
  visible: { opacity: 1, y: '0%', transition: { duration: 0.3 } },
  hidden: { opacity: 0, y: '100%' },
};

export default function Notification({
  i18nFunction,
}: {
  i18nFunction: (key: string) => string;
}) {
  const t = i18nFunction;
  const { isNotificationVisible, closeNotification } = useNotificationStore(
    (state) => ({
      isNotificationVisible: state.isNotificationVisible,
      closeNotification: state.closeNotification,
    }),
  );
  return (
    <motion.aside
      variants={variants}
      initial="hidden"
      animate={isNotificationVisible ? 'visible' : 'hidden'}
      className="fixed bottom-0 z-10 m-0 flex w-full justify-center"
    >
      <div className="relative flex justify-center rounded-t-lg bg-white py-3 pl-6 pr-10 text-sm text-black shadow-md">
        <span>{t('notification.geo_permission')}</span>
        <span
          className="absolute right-3 top-3 min-w-fit cursor-pointer"
          onClick={closeNotification}
        >
          <Icon name="X" iconSize="md" />
        </span>
      </div>
    </motion.aside>
  );
}
