import { Coordinates } from '@booking/types';
import { Wrapper } from '@googlemaps/react-wrapper';
import React, {
  cloneElement,
  isValidElement,
  useEffect,
  useRef,
  useState,
} from 'react';

type GoogleMapsProps = {
  coordinates: Coordinates;
  name: string;
  street: string;
  postalCode: string;
  city: string;
  trackingFunction?: (actionName: string) => void;
  i18nFunction: (key: string, nameSpace?: Record<'ns', string>) => string;
};

export default function Map({
  coordinates,
  name,
  street,
  postalCode,
  city,
  trackingFunction,
  i18nFunction,
}: GoogleMapsProps) {
  const [zoom] = useState(15);
  const [center] = useState<google.maps.LatLngLiteral>(coordinates);

  const t = i18nFunction;

  const trackClick = () => {
    if (trackingFunction) {
      trackingFunction('web_booking_pickDateTime_clickedOpenMap');
    }
  };

  const query = encodeURIComponent(
    `Dr. Dropin ${name}, ${street}, ${postalCode} ${city}`,
  );

  return (
    <div className="relative flex h-full">
      <Wrapper apiKey={`${process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}`}>
        <MapElement
          center={center}
          zoom={zoom}
          disableDefaultUI
          className="h-50 flex-grow rounded-lg"
        >
          <Marker position={coordinates} />
        </MapElement>
      </Wrapper>

      <div className="absolute bottom-5 left-5 cursor-pointer no-underline">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://maps.google.com/?q=${query}`}
          onClick={trackClick}
        >
          <span className="border-primary text-primary rounded-full border bg-white px-4 py-2 text-base leading-4 no-underline">
            {t('map.button_text', { ns: 'common' })}
          </span>
        </a>
      </div>
    </div>
  );
}

interface MapProps extends google.maps.MapOptions {
  children?: React.ReactNode;
  style?: { [key: string]: string };
  className?: string;
}

const MapElement = ({ children, style, className, ...options }: MapProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    if (ref.current && !map) {
      const newMap = new window.google.maps.Map(ref.current, {});
      newMap.setOptions(options);
      setMap(newMap);
    }
  }, [ref, map, options]);

  return (
    <>
      <div ref={ref} style={style} className={className} />
      {React.Children.map(children, (child) => {
        if (isValidElement(child)) {
          return cloneElement(child as React.ReactElement, { map });
        }
        return child;
      })}
    </>
  );
};

const Marker = (options: google.maps.MarkerOptions) => {
  const [marker, setMarker] = useState<google.maps.Marker>();

  useEffect(() => {
    if (!marker) {
      const newMarker = new google.maps.Marker(options);
      setMarker(newMarker);

      return () => {
        newMarker.setMap(null);
      };
    } else {
      marker.setOptions(options);
    }
  }, [marker, options]);

  return null;
};
