import { Locales, Practitioner } from '@booking/types';
import { RoundedButton } from '@booking/ui-web/src';
import BioPanel from '@components/BioPanel/BioPanel';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';

type Props = {
  practitioner: Practitioner;
};

export default function PractitionerInfo({ practitioner }: Props) {
  const locale = useRouter().locale as Locales;
  const { t } = useTranslation('booking');
  const { id, photoUrl, name, description } = practitioner;

  const [bioPanelOpen, setBioPanelOpen] = useState(false);

  const closePanel = () => setBioPanelOpen(false);

  return (
    <div key={id} className="flex w-full flex-col gap-4">
      <div className="flex items-center gap-4">
        {photoUrl && (
          <Image
            src={photoUrl}
            width={80}
            height={80}
            alt="Practitioner image"
            className="aspect-square rounded-full"
            style={{
              maxWidth: '100%',
              height: 'auto',
              objectFit: 'cover',
            }}
          />
        )}
        <span>{name}</span>
      </div>
      <p className="mb-4 text-sm">
        {description[locale] ? description[locale].substring(0, 100) : null}...
      </p>

      <BioPanel isOpen={bioPanelOpen} closePanel={closePanel}>
        <div className="flex flex-col gap-4 overscroll-contain">
          <span className="flex items-center gap-2 font-medium">
            {photoUrl && (
              <Image
                src={photoUrl}
                alt="Practitioner image"
                className="aspect-square rounded-md object-cover"
                width={72}
                height={72}
              />
            )}
            {name}
          </span>
          <p className="overscroll-contain text-sm">{description[locale]}</p>
        </div>
      </BioPanel>
      <RoundedButton
        onClick={() => setBioPanelOpen((prev) => !prev)}
        title={t('steps.practitioner_info.read_more')}
      />
    </div>
  );
}
