type DateSkeletonProps = {
  daysToList: 7 | 5;
};
// Non-consistent length of array will cause hydration issue with next
export default function DateSkeleton({ daysToList }: DateSkeletonProps) {
  return (
    <div className="relative flex w-full justify-start gap-2">
      {Array(daysToList)
        .fill(null)
        .map(() => null)}
    </div>
  );
}
