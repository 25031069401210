import { useEventListener } from '@booking/shared';
import { Icon } from '@drdropin-tech/theseus';
import classNames from 'classnames';
import Fuse from 'fuse.js';
import { CountryCallingCode, CountryCode } from 'libphonenumber-js/types';
import React, { FormEvent, useState } from 'react';
import { getCountryCallingCode } from 'react-phone-number-input';

import countryLabelsList from './countryTranslations.json';

type CountryLabels = { label: CountryCode; value: string };
type CountryLabelsList = { [CountryCode: string]: string };
type Country = {
  value: CountryCode;
  countryLabel: string;
  callingCode: CountryCallingCode;
};

type CountrySelectProps = {
  name: string;
  onChange: (value: CountryCode) => void;
  options: CountryCode[];
  className?: string;
  selected: CountryCode;
  i18nFunction: (key: string) => string;
};

const CountrySelect = ({
  options,
  selected,
  onChange,
  i18nFunction,
}: CountrySelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState<string>();

  const countryLabels: CountryLabelsList = countryLabelsList;

  const getCountryLabelsList = (country: string) =>
    Object.keys(countryLabels).reduce<CountryLabelsList>((acc, label) => {
      if (label === country) {
        return { label: countryLabels[label], value: label };
      }
      return acc;
    }, {} as CountryLabels);

  const countries = options.map((country): Country => {
    const callingCode = getCountryCallingCode(country);
    const countryLabel = getCountryLabelsList(country);
    return {
      value: country,
      countryLabel: countryLabel?.label,
      callingCode,
    };
  });

  const [selectedCountryCallingCode, setSelectedCountryCallingCode] = useState(
    () => {
      if (selected) {
        return countries.find((country) => country.value === selected)
          ?.callingCode;
      }
      return '';
    },
  );

  const handleChange = (country: Country) => {
    setSelectedCountryCallingCode(country.callingCode);
    onChange(country.value);
  };

  const sortedCountries = countries
    .sort((ca, cb) => ca.countryLabel?.localeCompare(cb.countryLabel))
    .filter((country) => !!country.countryLabel);

  return (
    <div className="relative text-black transition-all">
      <button
        className="w-23 input border-accent focus-input flex items-center rounded-lg border-2 pl-4 pr-8"
        onClick={(e) => {
          e.preventDefault();
          setIsOpen(!isOpen);
        }}
      >
        <span className="font-normal">+{selectedCountryCallingCode}</span>
        <div
          className={classNames(
            { 'rotate-180': isOpen },
            'absolute inset-y-0 right-1 flex items-center transition-all',
          )}
        >
          <Icon name="ChevronDown" iconSize="sm" />
        </div>
      </button>
      {isOpen ? (
        <DropDown
          countries={sortedCountries}
          onInputChange={setInputValue}
          onChange={handleChange}
          selected={selected}
          inputValue={inputValue}
          closeList={() => setIsOpen(false)}
          i18nFunction={i18nFunction}
        />
      ) : null}
    </div>
  );
};

type DropDownProps = {
  countries: Country[];
  selected: string;
  inputValue?: string;
  onChange: (selectedCountry: Country) => void;
  onInputChange: (query: string) => void;
  closeList: () => void;
  i18nFunction: (key: string) => string;
};

const DropDown = ({
  countries,
  selected,
  inputValue,
  onChange,
  onInputChange,
  closeList,
  i18nFunction,
}: DropDownProps) => {
  const fuse = new Fuse(countries, {
    keys: ['countryLabel', 'value', 'callingCode'],
  });

  const t = i18nFunction;

  const result = !!inputValue
    ? fuse.search(inputValue).map((val) => val.item)
    : countries;

  const handleChange = (country: Country) => {
    onChange(country);
    closeList();
  };

  const handleInputChange = (e: FormEvent<HTMLInputElement>) => {
    onInputChange(e.currentTarget.value);
  };

  useEventListener('keydown', (e) => {
    if (e.key === 'Escape') {
      closeList();
    }
  });

  return (
    <div
      className={classNames(
        'md:max-h-100 divide-primary/20 absolute -bottom-2 left-0 z-10 flex max-h-80 min-w-[280x] translate-y-full flex-col divide-y-2 overflow-scroll rounded-lg bg-white shadow-md transition-all md:min-w-[400px]',
      )}
    >
      <div className="p-4">
        <input
          type="search"
          placeholder={t(
            'steps.personal_details.input.placeholder.country_select',
          )}
          onChange={handleInputChange}
          className="input border-accent input-sm focus:ring-accent w-full rounded-md border-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white"
        />
      </div>
      <div>
        {result.map((country) => (
          <button
            key={country.value}
            className={classNames(
              { 'bg-accent': selected === country.value },
              'hover:bg-accent focus:outline-accent flex w-full cursor-pointer justify-between px-8 py-2 focus:outline-2',
            )}
            onClick={() => handleChange(country)}
          >
            <span>{country.countryLabel}</span>
            <span className="opacity-70">+{country.callingCode}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default CountrySelect;
